import { useQuery } from '@tanstack/vue-query'
import { queries } from '~/keys/index'
import { DEFAULT_CACHE_TIME } from '~/constants'
import type { SuccessResponse } from '~/types/response'
import type { ReleaseVersion } from '~/types/common'

const useReleaseVersions = (enabled?: boolean) => {
  const { fetch } = useAPIFetch()

  return useQuery({
    ...queries.versions.all,
    queryFn: () =>
      fetch<SuccessResponse<ReleaseVersion[]>>({
        method: 'GET',
        url: 'versions',
      }),
    staleTime: DEFAULT_CACHE_TIME,
    enabled,
    retry: false,
    select: data => data.data,
  })
}

export default useReleaseVersions
