/* eslint-disable no-console */
import { version } from '@/package.json'
import useAuthStore from '~/store/auth'

const useVersionCheck = () => {
  const authStore = useAuthStore()
  const isDevelopment = process.env.NODE_ENV === 'development'
  // TODO: temporary solution, need to clarify with .env
  const isDevelopmentServer = window?.origin === 'https://partners.thrivestate.dev'
  const { isSuperAdmin, isAuth } = storeToRefs(authStore)

  const { data: versions, refetch } = useReleaseVersions(isAuth.value)
  const { mutateAsync: logout } = useLogout()

  const checkVersion = async () => {
    if (!isAuth.value) {
      console.info('User is not authenticated, no need to check version')
      return
    }

    await refetch()

    if (!versions.value) {
      console.info('Versions not found, returning')
      return
    }

    const latestDeployedRelease = versions.value.find(v => v.frontend_partners_portal_status === 'deployed')

    // Frontend is not updated
    if (
      latestDeployedRelease &&
      latestDeployedRelease.frontend_partners_portal !== version &&
      !isSuperAdmin.value &&
      !isDevelopment &&
      !isDevelopmentServer
    ) {
      await logout()
      return window.location.reload()
    }
  }

  return {
    checkVersion,
  }
}

export default useVersionCheck
